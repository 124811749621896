.breadcrumbs {
    margin-bottom: 18px;
    &__inner {
        justify-content: space-between;
        align-items: center;
    }
    &__list {
        margin-right: 12px;
        list-style: none;
        &-item,
        &-link {
            display: inline-block;
        }
        &-link {
            font-size: 14px;
            color: rgb(var(--mischka));
            &:not([data-type="current"]) {
                color: rgb(var(--manatee));
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        &-slash {
            color: rgb(var(--manatee));
        }
    }
}